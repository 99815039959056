import React, { useEffect } from 'react';
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
//import { API, Storage } from "aws-amplify";
//import {
//  Flex,
//  Heading,
//  Image,
//  View,
//} from "@aws-amplify/ui-react";
//import { listNotes } from "./graphql/queries";
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import Home from './component/home';
import Gallery from './component/gallery';
import About from './component/about';
import background from "./b22.png";



function App() {

	useEffect(() => {
		document.title = 'Allowistic Art';
	  }, []);


  return (


  
      

      <Router>
		  <div className = 'Body' style={{ height: '100%', opacity: '1', backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundPosition:"",backgroundSize:"100vw auto", backgroundAttachment: 'fixed'  }}>




    
    <div className="App"  >
			
		
			
     
		<Routes>
				<Route exact path='/' element={< Home />}></Route>
				<Route exact path='/gallery' element={< Gallery />}></Route>
				<Route exact path='/about' element={< About />}></Route>
		</Routes>

    
		</div>


		<br></br><br></br>


		</div>
		
		
		<div className='footBox' ><a className = "emailLink" style={{ textDecoration: 'none' }} href="mailto:artist@allowistic.com">artist@allowistic.com</a> <br></br>Copyright © 2023 Allowistic Art - All Rights Reserved. </div>
		
	</Router>

      
 



  );
};


//export default withAuthenticator(App);
export default App;