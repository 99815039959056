import React from 'react';
import {
 // Flex,
  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';


function About (){
	return (<>
	


	<div className='navBarBox' >    <span className="navBarTitle">Allowistic Art </span>

	<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/">  Home</Link>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/gallery">Gallery</Link>
<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/about">About</Link>

</div>
<div className='pageContentWrapped' >

	<br></br>
	<Heading level={3} color ='#ffffff'>About</Heading>
	

	<p>
	

	Vincent Wilhelm Vaughn Strader, also known as the Allowistic Artist, is a dynamic and innovative artist who embraces the power of allowing in his creative process. 
	<br></br><br></br>With the motto "creative flows in the waves of now," Strader encourages viewers to let go of preconceived notions and societal pressures, and instead approach art as a playground. 
	<br></br><br></br>His work is an exploration of the present moment, where the act of allowing takes center stage, and the act of creating becomes a free-flowing expression of the self. Strader's pieces are not only visually striking, but also serve as a reminder to let go and embrace the present moment, to let creative energy flow freely and to make art for the pure joy of it.



	</p>
	</div>

	  </>
		
	
	)
}

export default About;
