import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import {
    Flex,
    Heading,
    Image,
//    View,
  } from "@aws-amplify/ui-react";
  import { listNotes } from "../graphql/queries";
  import "../App.css";
  import { Link } from 'react-router-dom';


function Contact (){

    const [notes, setNotes] = useState([]);

    useEffect(() => {
      fetchNotes();
    }, []);
  
    async function fetchNotes() {
      const apiData = await API.graphql({ query: listNotes });
      const notesFromAPI = apiData.data.listNotes.items;
      await Promise.all(
        notesFromAPI.map(async (note) => {
         // if (note.image) {
            const url = await Storage.get(note.name);
            note.image = url;
         // }
          return note;
        })
      );
      setNotes(notesFromAPI);
    }


return (
<>



	<div className='navBarBox' >    <span className="navBarTitle">Allowistic Art </span>
  <Link className='navBarLink' style={{ textDecoration: 'none' }} to="/">  Home</Link>
  <Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/gallery">Gallery</Link>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/about">About</Link>


</div>
<div className='pageContentLong'  >


<br></br>
<Heading level={3} color ='#ffffff'>Gallery</Heading>
      <br></br>
      
      
     
      {notes.map((note) => (
  <Flex
    key={note.id || note.name}
    direction="row"
    justifyContent="center"
    alignItems="center"
    marginBottom="4vh"
  >
    
    
    {note.image && (
      <Image
        src={note.image}
        alt={`visual aid for ${note.name}`}
        style={{ width: 600 }}
      />
    )}
  
  </Flex>
))}


    
</div>

</>

)
}

export default Contact;





