/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:fd735771-4a36-4092-80aa-d42d0e2ca69a",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_B0is8Zpby",
    "aws_user_pools_web_client_id": "5bdcqjkve2dbs6bh45m9goh082",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://gww66rsikjgolm7g7layv4dfyy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4kwbubwkgjbh7jxdnvtyrhpmha",
    "aws_user_files_s3_bucket": "allowisticadminapp224986ff4edb8440dba4dcf3ecc69193533-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
