import React from 'react';
import logo from './s1iwy.JPG';
import {
 // Flex,
  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';


function Home (){
	return (<>
	


	<div className='navBarBox' >    <span className="navBarTitle">Allowistic Art </span>

<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/">  Home</Link>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/gallery">Gallery</Link>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/about">About</Link>

</div>
<div className='pageContent'  >

	<br></br>
	<Heading level={3} color ='#ffffff'>Allowistic Art</Heading>
	<br></br>
	<img src={logo} className="App-logo" alt="logo" />
	<p></p>
	
	<span className = "pageContentMed2">by Vincent Strader</span>
		
	</div>

	  </>
		
	
	)
}

export default Home;
